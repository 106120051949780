export const getUrlPhoto = (
  bu: string | undefined,
  size: string,
  guid: string | undefined
): string => {
  if (!bu || !guid) return "/static/images/no-image-box.png";

  const radicalBU = () => {
    switch (bu) {
      case "Refleximmo":
        return "reflex";
      case "Optimhome":
        return "oh";
      default:
        return "capi";
    }
  };

  return `https://cdn.digitregroup.io/${radicalBU()}/thumb/${size}/${guid}`;
};

export default getUrlPhoto;
